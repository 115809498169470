import { FC, ReactNode, memo } from "react";

import { WithDataAttr } from "apps/website/types";

import Text from "../Text";
import { Align, AvailableTag, Color } from "../Text.map";

import {
  controlDisplayMap,
  controlSizeMap,
  TControlSize,
} from "./TextControl.map";

export interface IControlProps extends WithDataAttr {
  id?: string;
  tag?: AvailableTag;
  size: TControlSize
  align?: Align;
  color?: Color;
  children: ReactNode;
}

const TextControl: FC<IControlProps> = ({ id, size, align, color, tag = "h2", children, "data-testid": testId }) => (
  <Text
    id={id}
    data-testid={testId}
    size={controlSizeMap[size]}
    display={controlDisplayMap[size]}
    align={align}
    tag={tag}
    color={color}
  >
    { children }
  </Text>
);

export default memo(TextControl);
